<template>
  <div class="page-container">
  <Header/>
  <!-- <el-scrollbar class="layout-main-scrollbar"> -->
    <router-view></router-view>
  <Footer/>
</div>
  <!-- </el-scrollbar> -->
</template>

<script>
//引入组件
import Header from "@/layout/components/header";
import Footer from "@/layout/components/footer";

export default {
  name: 'home',
  components: {Header, Footer},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
    console.log("front布局-mounted2");
    //window.scrollTo(0, 0);
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.page-container{padding-top:100px;}
.layout-main-scrollbar {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 100px);
}
.layout-container {
  height: 100%;
  width: 100%;
}

.content-wrapper {
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
